var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form"
  }, [_c('AttachmentFolders', {
    attrs: {
      "editMode": _vm.isEditable ? 'edit' : 'preview',
      "allowTemplates": false,
      "type": "documents",
      "attachments": _vm.attachments,
      "protected": false,
      "singleFileMode": !_vm.isEditable ? true : false,
      "readOnlyMode": !_vm.isEditable
    },
    on: {
      "change": _vm.refreshAttachments
    },
    scopedSlots: _vm._u([{
      key: "heading",
      fn: function fn() {
        return [_c('sygni-container-title', {
          attrs: {
            "legacyStyling": false
          }
        }, [_vm._v("Documents")]), _c('sygni-input', {
          attrs: {
            "label": "SECTION NAME",
            "disabled": !_vm.isEditable
          },
          on: {
            "blur": _vm.setCampaignData
          },
          model: {
            value: _vm.sectionNames[_vm.selectedLanguage].documents,
            callback: function callback($$v) {
              _vm.$set(_vm.sectionNames[_vm.selectedLanguage], "documents", $$v);
            },
            expression: "sectionNames[selectedLanguage].documents"
          }
        })];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }