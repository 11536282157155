



















































































import Vue from 'vue'
import Component from 'vue-class-component';
import SygniContainerTitle from '@/components/layout/SygniContainerTitle.vue';
import SygniRectButton from '@/components/buttons/SygniRectButton.vue';
import SygniFileBox from '@/components/layout/SygniFileBox.vue';
import SygniFolderBox from '@/components/layout/SygniFolderBox.vue';
import SygniInput from '@/components/inputs/SygniInput.vue';
import SygniModal from '@/components/layout/SygniModal.vue';
import GenproxModal from '@/components/layout/GenproxModal.vue';
import SygniCheckbox from '@/components/inputs/SygniCheckbox.vue';
import { BTable } from 'bootstrap-vue';
import { TemplateAttachment, AttachmentListItem, Template } from '../store/types';
import { FileObject } from '@/modules/genprox/store/types';
import Utils, { UploadProgress } from '@/modules/shared/utils/utils';
import { AxiosRequestConfig } from 'axios';
import { Prop, Watch } from 'vue-property-decorator';
import _ from 'lodash';
import AttachmentModal from './AttachmentModal.vue';

@Component({
  components: { SygniContainerTitle, SygniRectButton, SygniFileBox, SygniFolderBox, SygniInput, SygniModal, GenproxModal, BTable, SygniCheckbox, AttachmentModal }
})
export default class AttachmentFolders extends Vue {
  @Prop({default: null}) template: Template;
  @Prop({default: []}) attachments: TemplateAttachment[];
  @Prop({default: 'edit'}) editMode: 'edit' | 'preview' | 'template';
  @Prop({default: false}) readOnlyMode: boolean;
  @Prop({ default: 'attachment' }) type: 'attachment' | 'document';
  @Prop() manageProductsEnabled: boolean;
  @Prop({default: true}) allowTemplates: boolean;
  @Prop({default: true}) protected: boolean;
  @Prop({default: 'Upload new files'}) uploadBtnText: string;
  @Prop({default: false}) singleFileMode: boolean;
  @Prop({default: false}) showPreviewAlways: boolean;
  @Prop({default: false}) handlePaths: boolean;
  @Prop({default: false}) showAutentiAction: boolean;

  areFilesLoading: boolean = false;
  attachmentsToUpload: TemplateAttachment[] = [];
  uploadProgress: UploadProgress = { progress: 0 };
  attachmentListItems: AttachmentListItem[] = [];
  isAttachmentModalLoading: boolean = false;
  editableAttachmentIndex: number | null = null;
  showAttachmentsModal: boolean = false;
  tempFolders: any[] = [];
  isDropAvailable: boolean = false;
  showAttachmentPreviewModal: boolean = false;
  selectedAttachment: any = null;

  get campaignFiles() {
    const files = _.groupBy(this.attachmentsToUpload, 'namespace')
    // eslint-disable-next-line no-unused-vars
    const formattedFiles: any = { folders: [], files: [] }

    if (this.tempFolders?.length) {
      const namespaces = _.uniq(this.attachmentsToUpload?.map((el: any) => el?.namespace ? el?.namespace : ''))
      const tempFolders = this.tempFolders?.filter((name: string) => !namespaces?.includes(name))
      formattedFiles.folders = []
      tempFolders?.forEach((folderName: string, index: number) => {
        formattedFiles.folders.push({ id: index + 1, folderName, files: [] })
      })
    }

    if (Object.keys(files)?.length) {
      Object.keys(files)?.forEach((namespace: string) => {
        if (namespace === 'undefined' || namespace === 'null' || !namespace) {
          if (formattedFiles?.files && formattedFiles?.files?.length) {
            formattedFiles.files = formattedFiles.files.concat(files[namespace])
          } else {
            formattedFiles.files = _.cloneDeep(files[namespace])
          }
        } else {
          formattedFiles.folders.push({ id: formattedFiles?.folders?.length + 1, folderName: namespace, files: files[namespace] })
        }
      })
    }

    formattedFiles.folders = _.sortBy(formattedFiles?.folders || [], 'folderName')

    formattedFiles?.folders?.forEach((folder: any) => {
      folder.files = _.sortBy(folder.files || [], 'name')
    })

    formattedFiles.files = _.sortBy(formattedFiles?.files || [], 'name')

    return formattedFiles
  }

  get enableNameChange() {
    if (this.singleFileMode) {
      return false
    }

    return !this.isReadOnlyMode
  }

  get showFileUpload() {
    if (this.singleFileMode) {
      return this.attachmentsToUpload?.length === 0 ? true : false
    }

    return true
  }

  get readOnly() {
    return (this.editMode === 'preview' || this.readOnlyMode);
  }

  get attachmentsToPost() {
    const ids = this.template?.attachments?.map((el: TemplateAttachment) => el.referenceId);

    return this.attachmentsToUpload?.filter((el: TemplateAttachment) => !ids?.includes(el.referenceId))
  }

  get attachmentsToDelete() {
    const ids = this.template?.attachments?.map((el: TemplateAttachment) => el.id);
    const attachmentsToUpload = this.attachmentsToUpload?.map((el: TemplateAttachment) => el.id);

    return ids?.filter((id: string) => !attachmentsToUpload?.includes(id))
  }

  get attachmentsToEdit() {
    const idsToInclude = this.template?.attachments?.map((el: TemplateAttachment) => el.id);
    const attachments: TemplateAttachment[] = [];

    idsToInclude.forEach((id: string) => {
      const attachment: TemplateAttachment = this.attachmentsToUpload?.find((el: TemplateAttachment) => el.id === id);
      const foundAttachment: TemplateAttachment = this.template?.attachments?.find((el: TemplateAttachment) => el.id === id)
      if(attachment && (foundAttachment?.name !== attachment?.name || foundAttachment?.sendToAutenti !== attachment?.sendToAutenti)) {
        attachments.push(attachment);
      }
    });

    return attachments;
  }

  get filteredAttachmentListItems() {
    const selectedAttachmentIds: string[] = this.attachmentsToUpload?.map((el: TemplateAttachment) => el.referenceId)

    return this.attachmentListItems.filter((el: AttachmentListItem) => !selectedAttachmentIds?.includes(el.referenceId));
  }

  get fileBoxAction() {
    if(this.editMode === 'preview') {
      return '';
    }

    return 'DELETE';
  }

  addNewFolderModal: { show: boolean, folderName: string } = {
    show: false,
    folderName: null,
  }

  async openFilePreview(file: any) {
    try {
      this.showAttachmentPreviewModal = true
      const fileData: any = await this.$store.dispatch('genprox/getFileById', file?.referenceId)
      this.selectedAttachment = fileData

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  closeAttachmentPreviewModal() {
    this.showAttachmentPreviewModal = false
    this.selectedAttachment = null
  }
  
  async downloadFile(file: any) {
    try {
      const fileData: any = await this.$store.dispatch('genprox/getFileById', file?.referenceId)
      this.downloadFileByUrl(fileData)

    } catch (e) {
      const errorMessage = this.$options.filters.errorHandler(e)
      this.$notify({
        duration: 2500,
        type: 'error',
        title: 'Error',
        text: errorMessage
      })
    }
  }

  openAddNewFolderModal() {
    this.$set(this.addNewFolderModal, 'show', true)
  }
  
  closeAddNewFolderModal() {
    this.$set(this.addNewFolderModal, 'show', false)
    this.$set(this.addNewFolderModal, 'folderName', null)
  }

  addNewFolder() {
    if (!this.tempFolders?.includes(this.addNewFolderModal?.folderName) && !this.campaignFiles?.folders?.map((el: any) => el?.folderName)?.includes(this.addNewFolderModal?.folderName)) {
      this.tempFolders.push(this.addNewFolderModal?.folderName)
    }
    
    this.closeAddNewFolderModal()
  }

  getFileIndex(id: string) {
    const foundIndex = this.attachmentsToUpload?.findIndex((el: any) => el?.id === id || el?.referenceId === id)

    return foundIndex >= 0 ? foundIndex : null
  }

  draggedFileId: string = null;

  handleFileDragStart(fileId: string) {
    this.draggedFileId = fileId
  }
  
  // eslint-disable-next-line no-unused-vars
  handleFileDragEnd(fileId: string) {
    this.draggedFileId = null
  }

  handleFolderDrop(folder: any) {
    const namespace = folder?.folderName
    const file: any = this.attachmentsToUpload?.find((el: any) => el?.id === this.draggedFileId || el?.referenceId === this.draggedFileId)
    const fileOldNamespace: any = _.clone(file?.namespace)
    
    if (file && namespace) {
      const namespaceItemsAmount = _.clone(this.attachmentsToUpload?.filter((el: any) => el?.namespace === folder?.folderName)?.length)
      this.$set(file, 'namespace', namespace)

      if (!namespaceItemsAmount) {
        if (this.tempFolders?.includes(folder?.folderName)) {
          const foundTempIndex = this.tempFolders?.findIndex((name: string) => name === folder?.folderName)
          if (foundTempIndex >= 0) {
            this.tempFolders.splice(foundTempIndex, 1)
          }
        }
      }

      if (namespaceItemsAmount === 1 && fileOldNamespace && fileOldNamespace !== folder?.folderName) {
        this.tempFolders.push(fileOldNamespace)
      }
    }

    this.$emit('change', this.attachmentsToUpload)
    this.$emit('update')
  }

  handleDragOver() {
    this.isDropAvailable = true
  }

  handleDragLeave() {
    this.isDropAvailable = false
  }

  handleFileDrop() {
    const file: any = this.attachmentsToUpload?.find((el: any) => el?.id === this.draggedFileId || el?.referenceId === this.draggedFileId)

    if (file) {
      this.$set(file, 'namespace', undefined)
    }
    this.$emit('change', this.attachmentsToUpload)
    this.isDropAvailable = false
  }

  deleteFolder(folder: any) {
    const filesToRemove = this.attachmentsToPost?.filter((el: any) => el?.namespace === folder?.folderName)?.map((el: any) => el?.id)

    if (this.tempFolders?.includes(folder?.folderName)) {
      const foundTempIndex = this.tempFolders?.findIndex((name: string) => name === folder?.folderName)
      if (foundTempIndex >= 0) {
        this.tempFolders.splice(foundTempIndex, 1)
      }
    }

    if (filesToRemove?.length) {
      this.attachmentsToUpload = _.cloneDeep(this.attachmentsToUpload?.filter((el: any) => !filesToRemove?.includes(el?.id)))
    }

    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  handlePreview(file: any) {
    if (this.editMode === 'preview' || this.isSupportedFile(file)) {
      this.$emit('openAttachment', file)
    }
  }

  keydownHandler(e: any) {
    // check if dot is pressed
    if(e.keyCode == 190 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
  }

  downloadFileByUrl(file: any) {
    Utils.downloadFileByUrl(file?.filePath, file?.fileName);
  }

  getFileBoxTooltip(file: any) {
    if (this.editMode === 'preview') {
      return '';
    }

    if (this.editMode === 'template') {
      const attachmentIds = this.attachments.map((el: any) => {
        return el.id
      });

      if (attachmentIds?.includes(file.id)) {
        return '';
      } else {
        return 'Delete';
      }
    }


    return 'Delete';
  }

  getFileBoxAction(file: any) {
    if(this.editMode === 'preview') {
      return '';
    }

    if(this.editMode === 'template') {
      const attachmentIds = this.attachments.map((el: any) => {
        return el.id
      });

      if(attachmentIds?.includes(file.id)) {
        return '';
      } else {
        return 'DELETE';
      }
    }


    return 'DELETE';
  }

  get isReadOnlyMode() {
    if(this.editMode === 'preview') {
      return true;
    }

    if(this.editMode === 'template') {
      return true;
    }

    return false;
  }

  get activeUserData() {
    return this.$store.getters['genprox/activeUserData'];
  }

  handleFileBoxAction(index: number) {
    if(this.editMode === 'preview') return;

    this.deleteFile(index);
  }

  handleFileBoxesOutsideClick(resetEditable: boolean = true) {
    this.attachmentsToUpload?.forEach((attachment: TemplateAttachment, index: number) => {
      if (this.$refs[`file-${index}`]) {
        ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).displayValue = attachment.name;
      }
    })
    if(resetEditable) {
      this.editableAttachmentIndex = null;
    }
  }

  isSupportedFile(file: any) {
    const extension = (file?.name?.includes('.')) ? file?.name?.split('.').pop() : '';

    if (extension?.toLowerCase() === 'pdf') {
      return true;
    }

    return false;
  }

  setSendToAutenti(file: any) {
    this.$set(file, 'sendToAutenti', !file?.sendToAutenti)
    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  handleExtension(attachment: TemplateAttachment, value: string) {
    let hasExtension: boolean = false;
    let extension: string = '';
    
    if(attachment.type === 'FILE') {
      extension = attachment.name.split('.').pop();
      hasExtension = extension ? true : false;
    }

    if(hasExtension) {
      // check if value contains extension and if not add this extension to value
      if(value.slice(value.length - extension.length) !== extension) {
        value = `${value}.${extension}`;
      }
    }

    return value;
  } 

  confirmFileBoxNameChange(index: number) {
    const value = ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).displayValue;
    const attachment: TemplateAttachment = this.attachmentsToUpload[index];
    
    this.attachmentsToUpload[index].name = this.handleExtension(attachment, value);

    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  setEditableAttachmentIndex(index: number) {
    this.editableAttachmentIndex = this.editableAttachmentIndex === index ? null : index;

    if(this.editableAttachmentIndex !== null) {
      this.handleFileBoxesOutsideClick(false);
      this.$nextTick(() => {
        ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).setFocus();
      })
    } else {
      this.confirmFileBoxNameChange(index);
    }
  }

  closeAttachmentsModal() {
    this.showAttachmentsModal = false;
  }

  openAttachmentsModal() {
    this.showAttachmentsModal = true;
  }

  addAttachments() {
    const attachmentsToAdd: TemplateAttachment[] = this.attachmentListItems.filter((el: AttachmentListItem) => el.selected).map((el: AttachmentListItem) => {
      const attachment: any = {
        name: el.name,
        referenceId: el.referenceId,
        type: 'TEMPLATE',
      };

      return attachment; 
    });

    attachmentsToAdd.forEach((attachment: TemplateAttachment) => {
      this.attachmentsToUpload.push(attachment);
    });

    this.resetAttachmentSelections();
    this.closeAttachmentsModal();
    this.$emit('update');
  }

  resetAttachmentSelections() {
    this.attachmentListItems = this.attachmentListItems.map((el: AttachmentListItem) => {
      el.selected = false;
      return el;
    });
  }

  deleteFolderFile(id: string) {
    const foundIndex = this.attachmentsToUpload?.findIndex((el: any) => el?.id === id)

    if (foundIndex >= 0) {
      this.deleteFile(foundIndex)
    }
  }

  deleteFile(index: number) {
    this.attachmentsToUpload.splice(index, 1);
    this.$emit('update');
    this.$emit('change', this.attachmentsToUpload);
  }

  toggleFileUpload() {
    (this.$refs.fileInput as HTMLInputElement).click()
  }

  handleFolderFileNameChange() {
    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  async uploadFiles(e: any) {
    const files = Array.from((e.target as HTMLInputElement).files);
    const config: AxiosRequestConfig = Utils.getUploadFileConfig(this.uploadProgress);
    try {
      const action = this.protected ? 'genprox/uploadFile' : 'genprox/uploadPublicFile';
      this.areFilesLoading = true;
      const promises: Array<Promise<any>> = [];
      files.forEach(async (file: File) => {
        promises.push(this.$store.dispatch(action, { file, config }));
      })

      const uploadedFiles = await Promise.all(promises);
      this.areFilesLoading = false;
      uploadedFiles.forEach((file: FileObject) => {
        const attachment: any = {
          name: file.fileName,
          type: 'FILE',
          referenceId: file.id,
        };

        if (this.showAutentiAction) {
          attachment.sendToAutenti = true
        }

        if (this.handlePaths) {
          attachment.path = file.filePath
        }

        this.$nextTick(() => {
          this.attachmentsToUpload.push(attachment);
        })
      });
    } catch (e) {
      e;
    }
    e.target.value = '';
    this.uploadProgress.progress = -1;
    this.$emit('update', this.singleFileMode ? this.attachmentsToUpload : undefined);
    this.$emit('change', this.attachmentsToUpload);
  }

  async mounted() {
    if(this.editMode === 'template') {
      if(!this.attachmentsToUpload?.length) {
        this.attachmentsToUpload = _.cloneDeep(this.attachments);
      }
    } else {
      this.attachmentsToUpload = _.cloneDeep(this.attachments);
    }

    if (!this.template?.isAttachment) {
      this.isAttachmentModalLoading = true;
      const items = await this.$store.dispatch('templates/getTemplateChoices', { type: 'attachment', context: this.activeUserData?.context?.id });
      items.forEach((item: Template) => {
        this.attachmentListItems.push({
          selected: false,
          referenceId: item.id,
          name: item.title,
        })
      })
      this.isAttachmentModalLoading = false;
    }
  }

  editableFolderIndex: number | null = null;

  setEditableFolderIndex(index: number) {
    this.editableFolderIndex = this.editableFolderIndex === index ? null : index;

    if(this.editableFolderIndex !== null) {
      this.handleFolderBoxesOutsideClick(false);
      this.$nextTick(() => {
        ((this.$refs[`folder-${index}`] as any[])[0] as SygniInput).setFocus();
      })
    } else {
      this.confirmFolderBoxNameChange(index);
    }
  }

  handleFolderBoxesOutsideClick(resetEditable: boolean = true) {
    const folderName = this.campaignFiles?.folders[this.editableFolderIndex]?.folderName
    if (folderName) {
      ((this.$refs[`folder-${this.editableFolderIndex}`] as any[])[0] as SygniInput).displayValue = folderName;
    }
    if(resetEditable) {
      this.editableFolderIndex = null;
    }
  }

  confirmFolderBoxNameChange(index: number) {
    const value = ((this.$refs[`folder-${index}`] as any[])[0] as SygniInput).displayValue;
    const namespaceToRemove = this.campaignFiles?.folders[index]?.folderName
    const filesToChange = this.attachmentsToUpload?.filter((el: any) => el?.namespace === namespaceToRemove)

    if (filesToChange?.length) {
      if (value) {
        filesToChange?.forEach((file: any) => {
          this.$set(file, 'namespace', value)
        })
        
        const foundTempIndex = this.tempFolders?.findIndex((name: string) => name === namespaceToRemove)
        if (foundTempIndex >= 0) {
          this.tempFolders.splice(foundTempIndex, 1)
        }
      }
    } else {
      const foundTempIndex = this.tempFolders?.findIndex((name: string) => name === namespaceToRemove)
      if (foundTempIndex >= 0) {
        this.$set(this.tempFolders, foundTempIndex, value)
      }
    }

    this.$emit('change', this.attachmentsToUpload);
    this.$emit('update');
  }

  @Watch('attachments') onAttachmentsUpdate() {
    if(this.editMode === 'template') {
      if(!this.attachmentsToUpload.length) {
        this.attachmentsToUpload = _.cloneDeep(this.attachments);
      }
    } else {
      this.attachmentsToUpload = _.cloneDeep(this.attachments);
    }
    this.$emit('change', this.attachmentsToUpload);
  }
}
