










































import Vue from 'vue'
import Component from 'vue-class-component'
import {Prop} from "vue-property-decorator";
import SygniFileBox from './SygniFileBox.vue';
import SygniInput from '../inputs/SygniInput.vue';

@Component({
  components: { SygniFileBox, SygniInput }
})
export default class SygniFolderBox extends Vue {
  @Prop() icon: string;
  @Prop() actionTooltip: string;
  @Prop({ default: false }) showPreview: boolean;
  @Prop({ default: [] }) files: any[];
  @Prop({default: false}) singleFileMode: boolean;
  @Prop({default: false}) isReadOnlyMode: boolean;

  editableAttachmentIndex: number | null = null;
  dropCounter: number = 0;

  get enableNameChange() {
    if (this.singleFileMode) {
      return false
    }

    return !this.isReadOnlyMode
  }

  isSupportedFile(file: any) {
    const extension = (file?.name?.includes('.')) ? file?.name?.split('.').pop() : '';

    if (extension?.toLowerCase() === 'pdf') {
      return true;
    }

    return false
  }

  handleFilePreview(file: any) {
    if (this.isSupportedFile(file)) {
      this.$emit('openFilePreview', file)
    }
  }

  handleFileDownload(file: any) {
    this.$emit('downloadFile', file)
  }

  handleDrop(e: any) {
    this.dropCounter = 0
    this.$emit('drop', e)
  }
  
  handleDragEnter() {
    this.dropCounter++;
  }
  
  handleDragLeave() {
    if (this.dropCounter > 0) {
      this.dropCounter--
    }
  }

  setEditableAttachmentIndex(index: number) {
    this.editableAttachmentIndex = this.editableAttachmentIndex === index ? null : index;

    if(this.editableAttachmentIndex !== null) {
      this.handleFileBoxesOutsideClick(false);
      this.$nextTick(() => {
        ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).setFocus();
      })
    } else {
      this.confirmFileBoxNameChange(index);
    }
  }

  handleFileBoxesOutsideClick(resetEditable: boolean = true) {
    this.files?.forEach((attachment: any, index: number) => {
      if (this.$refs[`file-${index}`]) {
        ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).displayValue = attachment.name;
      }
    })
    if(resetEditable) {
      this.editableAttachmentIndex = null;
    }
  }

  handleExtension(attachment: any, value: string) {
    let hasExtension: boolean = false;
    let extension: string = '';
    
    if(attachment.type === 'FILE') {
      extension = attachment.name.split('.').pop();
      hasExtension = extension ? true : false;
    }

    if(hasExtension) {
      // check if value contains extension and if not add this extension to value
      if(value.slice(value.length - extension.length) !== extension) {
        value = `${value}.${extension}`;
      }
    }

    return value;
  }

  confirmFileBoxNameChange(index: number) {
    const value = ((this.$refs[`file-${index}`] as any[])[0] as SygniInput).displayValue;
    const attachment: any = this.files[index];
    
    this.files[index].name = this.handleExtension(attachment, value);
    this.$emit('change', this.files);
    this.$emit('update');
  }

  keydownHandler(e: any) {
    // check if dot is pressed
    if(e.keyCode == 190 && !e.shiftKey) {
      e.preventDefault();
      return false;
    }
  }
}

