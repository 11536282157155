import { render, staticRenderFns } from "./AttachmentModal.vue?vue&type=template&id=6cf8db3e&scoped=true&"
import script from "./AttachmentModal.vue?vue&type=script&lang=ts&"
export * from "./AttachmentModal.vue?vue&type=script&lang=ts&"
import style0 from "./AttachmentModal.vue?vue&type=style&index=0&id=6cf8db3e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cf8db3e",
  null
  
)

export default component.exports