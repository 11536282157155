var render = function () {
  var _vm$campaignFiles, _vm$campaignFiles$fol, _vm$campaignFiles2, _vm$campaignFiles2$fo, _vm$campaignFiles3, _vm$campaignFiles4, _vm$campaignFiles4$fi, _vm$campaignFiles5, _vm$addNewFolderModal, _vm$addNewFolderModal2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['attachments', _vm.isReadOnlyMode ? 'read-only' : '']
  }, [_c('GenproxModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentsModal,
      expression: "showAttachmentsModal"
    }],
    staticClass: "attachments-modal",
    attrs: {
      "isLoading": _vm.isAttachmentModalLoading,
      "header": "Add attachments",
      "description": "Select attachments that you want to upload with this template.",
      "cancelText": "Close",
      "confirmText": "Add attachments"
    },
    on: {
      "close": _vm.closeAttachmentsModal,
      "cancel": _vm.closeAttachmentsModal,
      "confirm": _vm.addAttachments
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('b-table', {
          ref: "uploadFilesTable",
          staticClass: "sygni-b-inner-table modal-table",
          attrs: {
            "innertable": "true",
            "show-empty": true,
            "fields": [{
              key: 'name',
              sortable: false,
              class: ['name']
            }],
            "items": _vm.filteredAttachmentListItems
          },
          scopedSlots: _vm._u([{
            key: "cell(name)",
            fn: function fn(rowData) {
              return [_c('div', {
                staticClass: "text-left"
              }, [_c('sygni-checkbox', {
                staticClass: "secondary",
                model: {
                  value: rowData.item.selected,
                  callback: function callback($$v) {
                    _vm.$set(rowData.item, "selected", $$v);
                  },
                  expression: "rowData.item.selected"
                }
              }, [_vm._v(_vm._s(rowData.item.name))])], 1)];
            }
          }, {
            key: "empty",
            fn: function fn() {
              return [_c('div', {
                staticClass: "text-center py-3"
              }, [_vm._v("There are no " + _vm._s(_vm.type === 'attachment' ? 'attachments' : 'documents') + " to show")])];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  }), _vm._t("heading", function () {
    return [_c('sygni-container-title', [_vm._v(_vm._s(_vm.type === 'attachment' ? 'Attachments' : 'Documents'))])];
  }), !_vm.readOnly ? _c('div', {
    staticClass: "attachments-btns"
  }, [_c('sygni-rect-button', {
    staticClass: "outline gn-secondary",
    attrs: {
      "disabled": !_vm.showFileUpload,
      "loading": _vm.areFilesLoading
    },
    on: {
      "click": _vm.toggleFileUpload
    }
  }, [_vm._v(" " + _vm._s(_vm.uploadBtnText) + " ")]), _vm.editMode === 'edit' && _vm.allowTemplates ? _c('sygni-rect-button', {
    staticClass: "outline gn-secondary",
    on: {
      "click": _vm.openAttachmentsModal
    }
  }, [_vm._v(" Add " + _vm._s(_vm.type === 'attachment' ? 'attachments' : 'documents') + " ")]) : _vm._e()], 1) : _vm._e(), !_vm.readOnly ? _c('input', {
    ref: "fileInput",
    attrs: {
      "type": "file",
      "multiple": !_vm.singleFileMode,
      "hidden": ""
    },
    on: {
      "input": _vm.uploadFiles
    }
  }) : _vm._e(), _c('div', {
    staticClass: "folders mt-5 mb-5"
  }, [_c('p', {
    staticClass: "title mb-3"
  }, [_vm._v("Folders")]), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleFolderBoxesOutsideClick,
      expression: "handleFolderBoxesOutsideClick"
    }],
    staticClass: "attachments-listing"
  }, [((_vm$campaignFiles = _vm.campaignFiles) === null || _vm$campaignFiles === void 0 ? void 0 : (_vm$campaignFiles$fol = _vm$campaignFiles.folders) === null || _vm$campaignFiles$fol === void 0 ? void 0 : _vm$campaignFiles$fol.length) == 0 ? _c('div', {
    staticClass: "attachments-empty"
  }, [_c('p', {
    staticClass: "attachments-empty-header"
  }, [_vm._v("No folders added")])]) : _vm._e(), (_vm$campaignFiles2 = _vm.campaignFiles) !== null && _vm$campaignFiles2 !== void 0 && (_vm$campaignFiles2$fo = _vm$campaignFiles2.folders) !== null && _vm$campaignFiles2$fo !== void 0 && _vm$campaignFiles2$fo.length ? _vm._l((_vm$campaignFiles3 = _vm.campaignFiles) === null || _vm$campaignFiles3 === void 0 ? void 0 : _vm$campaignFiles3.folders, function (folder, index) {
    return _c('sygni-folder-box', {
      key: "".concat(folder === null || folder === void 0 ? void 0 : folder.folderName, "-").concat(index),
      attrs: {
        "icon": 'DELETE',
        "actionTooltip": "Delete folder and files inside",
        "files": folder === null || folder === void 0 ? void 0 : folder.files,
        "singleFileMode": _vm.singleFileMode,
        "isReadOnlyMode": _vm.isReadOnlyMode
      },
      on: {
        "dragstart": _vm.handleFileDragStart,
        "dragend": _vm.handleFileDragEnd,
        "deleteFile": _vm.deleteFolderFile,
        "drop": function drop($event) {
          return _vm.handleFolderDrop(folder);
        },
        "change": _vm.handleFolderFileNameChange,
        "click": function click($event) {
          return _vm.deleteFolder(folder);
        },
        "downloadFile": _vm.downloadFile,
        "openFilePreview": _vm.openFilePreview
      },
      scopedSlots: _vm._u([{
        key: "additionalActions",
        fn: function fn() {
          return [_vm.enableNameChange ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.editableFolderIndex === index ? 'Set name' : 'Edit name',
              expression: "(editableFolderIndex === index) ? 'Set name' : 'Edit name'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-folder-box__action",
            on: {
              "click": function click($event) {
                return _vm.setEditableFolderIndex(index);
              }
            }
          }, [(_vm.editableFolderIndex === index ? false : true) ? _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('EDIT'),
              "alt": "Edit"
            }
          }) : _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('APPROVE'),
              "alt": "Confirm"
            }
          })]) : _vm._e()];
        },
        proxy: true
      }], null, true)
    }, [_c('sygni-input', {
      ref: "folder-".concat(index),
      refInFor: true,
      attrs: {
        "value": folder === null || folder === void 0 ? void 0 : folder.folderName,
        "disabled": _vm.editableFolderIndex === index ? false : true
      },
      on: {
        "keydown": _vm.keydownHandler,
        "keyup": function keyup($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
          return _vm.setEditableFolderIndex(index);
        }
      }
    })], 1);
  }) : _vm._e()], 2), !_vm.readOnly ? _c('sygni-rect-button', {
    staticClass: "mt-3 w-auto filled gn-primary",
    attrs: {
      "plusIcon": true
    },
    on: {
      "click": _vm.openAddNewFolderModal
    }
  }, [_vm._v("Add new folder")]) : _vm._e()], 1), _c('div', {
    staticClass: "files"
  }, [_c('p', {
    staticClass: "title mb-3"
  }, [_vm._v("Files")]), _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleFileBoxesOutsideClick,
      expression: "handleFileBoxesOutsideClick"
    }],
    class: ['attachments-listing', _vm.isDropAvailable ? 'dragover' : ''],
    on: {
      "dragenter": function dragenter($event) {
        $event.preventDefault();
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
        return _vm.handleDragOver.apply(null, arguments);
      },
      "dragleave": function dragleave($event) {
        $event.preventDefault();
        return _vm.handleDragLeave.apply(null, arguments);
      },
      "drop": _vm.handleFileDrop
    }
  }, [((_vm$campaignFiles4 = _vm.campaignFiles) === null || _vm$campaignFiles4 === void 0 ? void 0 : (_vm$campaignFiles4$fi = _vm$campaignFiles4.files) === null || _vm$campaignFiles4$fi === void 0 ? void 0 : _vm$campaignFiles4$fi.length) == 0 ? _c('div', {
    staticClass: "attachments-empty"
  }, [_c('p', {
    staticClass: "attachments-empty-header"
  }, [_vm._v("No uploaded " + _vm._s(_vm.type === 'attachment' ? 'attachments' : 'documents') + " yet")])]) : _vm._e(), _vm._l((_vm$campaignFiles5 = _vm.campaignFiles) === null || _vm$campaignFiles5 === void 0 ? void 0 : _vm$campaignFiles5.files, function (file) {
    return _c('sygni-file-box', {
      key: _vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)),
      class: [_vm.editableAttachmentIndex === _vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)) ? 'active' : ''],
      attrs: {
        "draggable": !_vm.isReadOnlyMode && _vm.editableAttachmentIndex === null ? true : false,
        "actionTooltip": _vm.getFileBoxTooltip(file),
        "icon": _vm.getFileBoxAction(file),
        "readOnlyMode": _vm.readOnlyMode
      },
      on: {
        "dragstart": function dragstart($event) {
          return _vm.handleFileDragStart((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId));
        },
        "dragend": function dragend($event) {
          return _vm.handleFileDragEnd((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId));
        },
        "click": function click($event) {
          _vm.handleFileBoxAction(_vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)));
        }
      },
      scopedSlots: _vm._u([{
        key: "additionalActions",
        fn: function fn() {
          return [_vm.enableNameChange ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.editableAttachmentIndex === _vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)) ? 'Set name' : 'Edit name',
              expression: "(editableAttachmentIndex === getFileIndex(file?.id || file?.referenceId)) ? 'Set name' : 'Edit name'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                _vm.setEditableAttachmentIndex(_vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)));
              }
            }
          }, [(_vm.editableAttachmentIndex === _vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)) ? false : true) ? _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('EDIT'),
              "alt": "Edit"
            }
          }) : _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('APPROVE'),
              "alt": "Confirm"
            }
          })]) : _vm._e(), _vm.isSupportedFile(file) ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Preview',
              expression: "'Preview'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.openFilePreview(file);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('Fund'),
              "alt": "Preview"
            }
          })]) : _vm._e(), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Download',
              expression: "'Download'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.downloadFile(file);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('DOWNLOAD'),
              "alt": "Download"
            }
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('sygni-input', {
      ref: "file-".concat(_vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId))),
      refInFor: true,
      attrs: {
        "value": file.name,
        "disabled": _vm.editableAttachmentIndex === _vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)) ? false : true
      },
      on: {
        "keydown": _vm.keydownHandler,
        "keyup": function keyup($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }

          _vm.setEditableAttachmentIndex(_vm.getFileIndex((file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId)));
        }
      }
    })], 1);
  })], 2)]), (_vm$addNewFolderModal = _vm.addNewFolderModal) !== null && _vm$addNewFolderModal !== void 0 && _vm$addNewFolderModal.show ? _c('GenproxModal', {
    attrs: {
      "header": "New folder",
      "cancelText": "Cancel",
      "confirmText": "Add folder",
      "disabled": !((_vm$addNewFolderModal2 = _vm.addNewFolderModal) !== null && _vm$addNewFolderModal2 !== void 0 && _vm$addNewFolderModal2.folderName)
    },
    on: {
      "close": _vm.closeAddNewFolderModal,
      "cancel": _vm.closeAddNewFolderModal,
      "confirm": _vm.addNewFolder
    },
    scopedSlots: _vm._u([{
      key: "content",
      fn: function fn() {
        return [_c('sygni-input', {
          attrs: {
            "label": "Set folder name",
            "legacyStyling": false,
            "smallerActionsPadding": true
          },
          model: {
            value: _vm.addNewFolderModal.folderName,
            callback: function callback($$v) {
              _vm.$set(_vm.addNewFolderModal, "folderName", $$v);
            },
            expression: "addNewFolderModal.folderName"
          }
        })];
      },
      proxy: true
    }], null, false, 1052960438)
  }) : _vm._e(), _c('AttachmentModal', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showAttachmentPreviewModal,
      expression: "showAttachmentPreviewModal"
    }],
    attrs: {
      "useCdn": true,
      "attachment": _vm.selectedAttachment
    },
    on: {
      "close": _vm.closeAttachmentPreviewModal
    }
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }