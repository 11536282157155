var render = function () {
  var _vm$files;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.handleFileBoxesOutsideClick,
      expression: "handleFileBoxesOutsideClick"
    }],
    class: ['sygni-folder-box', _vm.dropCounter > 0 ? 'dragover' : ''],
    on: {
      "dragenter": function dragenter($event) {
        $event.preventDefault();
        return _vm.handleDragEnter.apply(null, arguments);
      },
      "dragover": function dragover($event) {
        $event.preventDefault();
      },
      "dragleave": function dragleave($event) {
        $event.preventDefault();
        return _vm.handleDragLeave.apply(null, arguments);
      },
      "drop": _vm.handleDrop
    }
  }, [_c('div', {
    staticClass: "sygni-folder-box__container"
  }, [_c('div', {
    staticClass: "sygni-folder-box__inner"
  }, [_vm._t("default")], 2), _vm._t("additionalActions"), _vm.showPreview ? _c('div', {
    staticClass: "sygni-folder-box__action"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")('Fund'),
      "alt": "Preview"
    }
  })]) : _vm._e(), _vm.icon && !_vm.isReadOnlyMode ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      value: _vm.actionTooltip,
      expression: "actionTooltip",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "sygni-folder-box__action",
    on: {
      "click": function click($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("getIcon")(_vm.icon),
      "alt": "Action"
    }
  })]) : _vm._e()], 2), _c('div', {
    staticClass: "sygni-folder-box__files"
  }, [_c('div', {
    staticClass: "sygni-folder-box__files-list"
  }, [!((_vm$files = _vm.files) !== null && _vm$files !== void 0 && _vm$files.length) ? _c('div', {
    staticClass: "sygni-folder-box-empty"
  }, [_c('p', {
    staticClass: "sygni-folder-box-empty-header"
  }, [_vm._v("No uploaded attachments yet")])]) : _vm._l(_vm.files, function (file, index) {
    return _c('sygni-file-box', {
      key: file === null || file === void 0 ? void 0 : file.id,
      class: [_vm.editableAttachmentIndex === index ? 'active' : ''],
      attrs: {
        "readOnlyMode": _vm.isReadOnlyMode,
        "draggable": _vm.editableAttachmentIndex === null && !_vm.isReadOnlyMode ? true : false,
        "icon": 'DELETE',
        "actionTooltip": "Delete"
      },
      on: {
        "dragstart": function dragstart($event) {
          return _vm.$emit('dragstart', (file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId));
        },
        "dragend": function dragend($event) {
          return _vm.$emit('dragend', (file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId));
        },
        "click": function click($event) {
          return _vm.$emit('deleteFile', (file === null || file === void 0 ? void 0 : file.id) || (file === null || file === void 0 ? void 0 : file.referenceId));
        }
      },
      scopedSlots: _vm._u([{
        key: "additionalActions",
        fn: function fn() {
          return [_vm.enableNameChange ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: _vm.editableAttachmentIndex === index ? 'Set name' : 'Edit name',
              expression: "(editableAttachmentIndex === index) ? 'Set name' : 'Edit name'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.setEditableAttachmentIndex(index);
              }
            }
          }, [(_vm.editableAttachmentIndex === index ? false : true) ? _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('EDIT'),
              "alt": "Edit"
            }
          }) : _c('img', {
            attrs: {
              "src": _vm._f("getIcon")('APPROVE'),
              "alt": "Confirm"
            }
          })]) : _vm._e(), _vm.isSupportedFile(file) ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Preview',
              expression: "'Preview'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.handleFilePreview(file);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('Fund'),
              "alt": "Preview"
            }
          })]) : _vm._e(), _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip.hover",
              value: 'Download',
              expression: "'Download'",
              modifiers: {
                "hover": true
              }
            }],
            staticClass: "sygni-file-box__action",
            on: {
              "click": function click($event) {
                return _vm.handleFileDownload(file);
              }
            }
          }, [_c('img', {
            attrs: {
              "src": _vm._f("getIcon")('DOWNLOAD'),
              "alt": "Download"
            }
          })])];
        },
        proxy: true
      }], null, true)
    }, [_c('sygni-input', {
      ref: "file-".concat(index),
      refInFor: true,
      attrs: {
        "value": file === null || file === void 0 ? void 0 : file.name,
        "disabled": _vm.editableAttachmentIndex === index ? false : true
      },
      on: {
        "keydown": _vm.keydownHandler,
        "keyup": function keyup($event) {
          if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
          return _vm.setEditableAttachmentIndex(index);
        }
      }
    })], 1);
  })], 2)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }